<template>
  <div style="height: 75vh;padding-bottom: 1rem">
    <!-- <navigation-bar /> -->
    <mescroll-vue ref="mescroll" :down="mescrollDown" :up="mescrollUp" @init="mescrollInit">
      <ul v-if="dataList.length > 0" class="product-list">
        <li v-for="(i, idx) in dataList" :key="idx" :style="{
          'box-shadow':
            itemChecked == i.code
              ? '0 0 0.1rem 0.1rem #EA392B'
              : '0 0 0.1rem 0 #00000050',
        }" class="product-list-item" @click="(itemChecked = i.code), (purchaseItem = i)">
          <van-card :thumb="i.logoUrl" :title="i.name" class="commodity-card" @click="ViewDetails(i)">
            <template #desc>
              <div v-if="Count > 0 && checked" class="align-items-center">
                <div class="text-gradient" style="
                    color: #ea392b;
                    background-image: linear-gradient(
                      to right,
                      #f15f3e,
                      #ea392b
                    );
                  ">
                  ¥<span style="font-size: large">{{
                    $GlobalEvent.towNumber(i.faceValue * 0.6)
                  }}</span>
                </div>
                <div style="color: #d8d8d8; margin: 0 0.5rem">|</div>
                <div class="text-gradient" style="
                    color: #ea392b50;
                    background-image: linear-gradient(
                      to right,
                      #f15f3e50,
                      #ea392b50
                    );
                  ">
                  原价 ¥<span class="delete-line">{{
                    $GlobalEvent.towNumber(i.faceValue)
                  }}</span>
                </div>
              </div>
              <div v-else>
                <div class="text-gradient" style="background-image: linear-gradient(to right,#f15f3e,#ea392b);">
                  ¥<span style="font-size: x-large">{{ $GlobalEvent.towNumber(i.faceValue) }}</span>
                </div>
              </div>
            </template>
            <template #tags>
              <div class="align-items-center" style="justify-content: space-between">
                <div v-if="Count > 0 && checked" class="tags-left">
                  <van-tag color="#EA392B" round size="medium">限时折扣</van-tag>
                  有效期 {{ $GlobalEvent.endOfYear() }}
                </div>
                <div v-else class="tags-left">
                  <van-tag color="#EA392B" round size="medium">温馨提示</van-tag>
                  {{ Count > 0 ? "使用折扣券更优惠" : "暂无折扣券" }}
                </div>
                <van-button color="linear-gradient(to right, #F15F3E, #EA392B)" size="mini"
                  style="border-radius: 0.25rem; padding: 0 0.5rem">
                  查看详情
                </van-button>
              </div>
            </template>
          </van-card>
        </li>
      </ul>
      <van-empty v-else description="暂无权益券" image-size="300" />
    </mescroll-vue>

    <!--     <div class="bottom-btn">
      <div v-if="Count >= 0" class="align-items-center agrmnt-style">
        <div class="align-items-center" @click="checked = !checked">
          <div :class="checked ? 'check-dot' : 'default-dot'" class="dot"></div>
          <van-tag color="#FFEFD4" round size="large" text-color="#000">
            使用 <span style="color: #F15F3E;margin: 0 0.5rem" @click.stop="couponPop = true">权益年卡6折购买券</span>
            <img alt="" src="@/assets/coupon-icon.png" style="width: 0.875rem;height: 0.75rem">
            <span style="margin: 0 0.5rem">x{{ Count }}</span>
          </van-tag>
        </div>
      </div>
      <van-button block color="linear-gradient(to right, #F15F3E, #EA392B)" round @click="popupShow = true">
        立即购买
      </van-button>
    </div> -->

    <van-popup v-model="couponPop" :style="{ height: '45%' }" position="bottom " round>
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" class="van-list">
        <van-radio-group v-model="radio">
          <van-row v-for="(el, idx) in listData" :key="idx" gutter="20" class="align-items-center"
            style="margin-bottom: 0.5rem">
            <van-col span="21" class="coupon-item-style align-items-center">
              <div style="color: #EA392B;padding: 0 15% 0 5%">
                <div>
                  <strong>
                    <strong style="font-size: xx-large">{{ el.discount }}</strong>
                    <small>折</small>
                  </strong>
                </div>
                <div><small>{{ el.type }}</small></div>
              </div>
              <div style="font-size: small;color: #FFEFD4">
                <div>
                  <strong>{{ el.title }}</strong>
                </div>
                <div style="margin: 0.1rem 0">券码来源：{{ el.source }}</div>
                <div>{{ el.time }}</div>
              </div>
            </van-col>
            <van-col span="3">
              <van-radio :name="el.id">
                <template #icon="props">
                  <img width="20" height="20"
                    :src="props.checked ? require('@/assets/Selected-icon.png') : require('@/assets/unSelected-icon.png')"
                    alt="">
                </template>
              </van-radio>
            </van-col>
          </van-row>
        </van-radio-group>
      </van-list>
      <van-row class="coupon-btn" gutter="20">
        <van-col span="12">
          <van-button round color="#EA392B" plain @click="couponPop = false">返回</van-button>
        </van-col>
        <van-col span="12">
          <van-button round color="linear-gradient(to right, #F15F3E, #EA392B)"
            @click="couponPop = false">确认</van-button>
        </van-col>
      </van-row>
    </van-popup>

    <van-popup v-model="popupShow" :style="{ height: '45%' }" position="bottom " round>
      <div v-show="!confirmed" class="bottom-popup">
        <h3 class="bottom-popup-title">请输入您的充值账号</h3>
        <div style="position: relative">
          <img alt="" class="mobile-icon" src="@/assets/mobile-icon.png" />
          <van-field v-model="topUpAccount" :error="error.bool" :formatter="formatter" class="ipt-style" clearable
            format-trigger="onBlur" maxlength="11" placeholder="请输入正确的充值账号" type="number" />
          <div class="align-items-center paste" @click="pasteEvent">
            <img alt="" src="@/assets/copy-icon.png" style="width: 14px; height: 15px; margin: 0 0.5rem" />粘贴
          </div>
        </div>
        <div style="padding: 0 2rem">
          <div class="align-items-center agrmnt-style" style="margin: 1.5rem 0">
            <van-tag color="#FFEFD4" round size="large" style="margin: 1rem 0" text-color="#F15F3E">
              <span v-if="topUpAccount">
                正在为{{
                  $GlobalEvent.Hyposensitization(topUpAccount)
                }}充值，请核对账号！
              </span>
              <span v-else>本产品为虚拟物品，一经售出将不支持退换</span>
            </van-tag>
          </div>
          <van-button block color="linear-gradient(to right, #F15F3E, #EA392B)" round @click="Confirmed">
            我已核对确认充值账号
          </van-button>
        </div>
      </div>
      <div v-show="confirmed" class="bottom-popup">
        <van-card :thumb="purchaseItem.logoUrl" :title="purchaseItem.name" class="commodity-card">
          <template #desc>
            <div v-if="Count > 0 && checked" class="align-items-center">
              <div class="text-gradient" style="
                  background-image: linear-gradient(to right, #f15f3e, #ea392b);
                ">
                ¥<span style="font-size: x-large">{{
                  $GlobalEvent.towNumber(purchaseItem.faceValue * 0.6)
                }}</span>
              </div>
              <div style="color: #d8d8d8; margin: 0 0.5rem">|</div>
              <div class="text-gradient" style="background-image: linear-gradient(to right,#f15f3e50,#ea392b50);
                ">
                原价 ¥<span class="delete-line">{{
                  $GlobalEvent.towNumber(purchaseItem.faceValue)
                }}</span>
              </div>
            </div>
            <div v-else>
              <div class="text-gradient" style="
                  background-image: linear-gradient(to right, #f15f3e, #ea392b);
                ">
                ¥<span style="font-size: x-large">{{
                  $GlobalEvent.towNumber(purchaseItem.faceValue)
                }}</span>
              </div>
            </div>
          </template>
          <template #tags>
            <div style="color: #999999">
              折后立减：¥
              {{ $GlobalEvent.towNumber(purchaseItem.faceValue * 0.4) }}
            </div>
            <div style="color: #999999">
              充值账号：{{ $GlobalEvent.Hyposensitization(topUpAccount) }}
            </div>
          </template>
        </van-card>
        <div style="padding: 0 2rem">
          <div class="align-items-center agrmnt-style">
            <van-tag color="#FFEFD4" round size="large" style="margin: 2.5rem 0" text-color="#F15F3E">
              <span>本产品为虚拟物品，一经售出将不支持退换</span>
            </van-tag>
          </div>
          <div class="align-items-center">
            <img alt="" src="@/assets/back-icon.png" style="width: 2rem; height: 2rem; margin-right: 1rem"
              @click="confirmed = false" />
            <van-button block color="linear-gradient(to right, #F15F3E, #EA392B)" round @click="Payment(purchaseItem)">
              使用微信支付
            </van-button>
          </div>
        </div>
      </div>
    </van-popup>
    <!--提示弹窗-->
    <van-popup v-model="error.bool" :overlay="false" round style="width: 55%">
      <div class="align-items-center" style="padding: 1rem">
        <img alt="" src="@/assets/toast-icon.png" style="width: 1.563rem; height: 1.563rem; margin-right: 0.5rem" />
        <span style="color: #4c4c4c">{{ error.msg }}!</span>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {
  authorization,
  commodityList,
  couponCount,
  payOrder,
} from "@/utils/api";

export default {
  name: "homeIdx",
  data() {
    return {
      /*       radio: 0,
            listData: [
              {
                id: 1,
                discount: 6,
                type: '无门槛',
                title: '仅限【权益年卡6折购买】',
                source: '红卡盲盒抽奖',
                time: '2024.06.26—2025.03.31'
              }, {
                id: 2,
                discount: 6,
                type: '无门槛',
                title: '仅限【权益年卡6折购买】',
                source: '红卡盲盒抽奖',
                time: '2024.06.26—2025.03.31'
              }, {
                id: 3,
                discount: 6,
                type: '无门槛',
                title: '仅限【权益年卡6折购买】',
                source: '红卡盲盒抽奖',
                time: '2024.06.26—2025.03.31'
              }, {
                id: 4,
                discount: 6,
                type: '无门槛',
                title: '仅限【权益年卡6折购买】',
                source: '红卡盲盒抽奖',
                time: '2024.06.26—2025.03.31'
              }
            ],
            loading: false,
            finished: true, */
      mescroll: null,
      mescrollDown: {},
      mescrollUp: {
        // 上拉加载的配置.
        callback: this.upCallback, // 上拉回调,此处简写; 相当于 callback: function(page, mescroll) { }
        dataList: [],
        htmlNodata: '<p class="upwarp-nodata">-- 已经到底啦 --</p>'
      },
      purchaseItem: {},
      Count: 0,
      dataList: [],
      itemChecked: null,
      confirmed: false,
      checked: true,
      popupShow: false,
      couponPop: false,
      topUpAccount: "",
      error: {
        bool: false,
        msg: "",
      },
    };
  },
  /*  // activated是页面激活后的钩子函数，一进页面就会触发
    activated() {
      // 显示时

    },*/
  /*// deactivated 离开页面的钩子函数，一离开页面就会触发
  deactivated() {
    // 不显示时
    console.log(2)
  },*/
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // 滚动到原来的列表位置,恢复顶部按钮和isBounce的配置
      vm.$refs.mescroll && vm.$refs.mescroll.beforeRouteEnter();
    });
  },
  beforeRouteLeave(to, from, next) {
    // 记录列表滚动的位置,隐藏顶部按钮和isBounce的配置
    this.$refs.mescroll && this.$refs.mescroll.beforeRouteLeave();
    next();
  },
  methods: {
    Authorization(page, mescroll) {
      authorization({
        code: decodeURI(this.$route.query.data),
      }).then((res) => {
        if (res.token) {
          this.$GlobalEvent.setStorage("token", res.token);
          this.$GlobalEvent.setStorage("phone", res.phone);
          this.$router.replace("/");
          this.GetCouponCount();
          this.CommodityList(page, mescroll);
        }
      });
    },
    // mescroll组件初始化的回调,可获取到mescroll对象
    mescrollInit(mescroll) {
      this.mescroll = mescroll;
    },
    upCallback(page, mescroll) {
      if (this.$route.query.data) {
        this.Authorization(page, mescroll);
      } else {
        this.GetCouponCount();
        this.CommodityList(page, mescroll);
      }
    },
    ViewDetails(i) {
      this.purchaseItem = i;
      this.itemChecked = i.code;
      this.$router.push({
        path: "/detailsPage",
        query: {
          code: i.code,
        },
      });
    },
    CommodityList(page, mescroll) {
      commodityList({
        page: page.num,
        size: page.size,
      })
        .then((res) => {
          if (page.num === 1) this.dataList = [];
          this.dataList = this.dataList.concat(res);
          this.itemChecked = this.dataList[0].code;
          this.purchaseItem = this.dataList[0];
          this.$nextTick(() => {
            mescroll.endSuccess(res.length);
          });
        })
        .catch(() => {
          // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
          mescroll.endErr();
        });
    },
    GetCouponCount() {
      if (this.$GlobalEvent.getStorage('token')) {
        couponCount({}).then((res) => {
          this.Count = res.count;
        });
      }
    },
    Payment(item) {
      payOrder({
        event: "六折购买", //支付描述
        amount:
          this.Count > 0 && this.checked
            ? this.$GlobalEvent.towNumber(item.faceValue * 0.6)
            : this.$GlobalEvent.towNumber(item.faceValue), //支付金额
        payOrderForm: {
          merchandiseId: item.id, //商品id
          account: this.topUpAccount, //充值账号
        },
      }).then((res) => {
        console.log(res);
      });

      /*      this.$router.push({
              path: '/rechargeInfo',
              query: {
                status: status
              }
            })*/
    },
    Confirmed() {
      var myreg = /^1[3-9]\d{9}$/;
      if (this.topUpAccount && myreg.test(this.topUpAccount)) {
        this.confirmed = true;
      } else if (!this.topUpAccount) {
        this.errorEvent();
      } else {
        this.errorEvent("您的手机号格式错误");
      }
    },
    pasteEvent() {
      let self = this;
      navigator.clipboard.readText().then((text) => {
        self.topUpAccount = text;
        self.formatter();
      });
    },
    errorEvent(msg) {
      this.error = {
        bool: true,
        msg: msg || "请输入您的充值账号",
      };
      setTimeout(() => {
        this.error.bool = false;
      }, 1000);
      return false;
    },
    formatter(value) {
      var myreg = /^1[3-9]\d{9}$/;
      if (value) {
        if (!myreg.test(value) && value.length < 11) {
          this.errorEvent("您的手机号格式错误");
          return value;
        }
      } else {
        this.errorEvent();
        return null;
      }
      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
.van-list {
  padding: 1rem 1rem 4.625rem 1rem;
}

.coupon-btn {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  padding: 1rem 0;

  .van-col {
    text-align: center;
    padding: 0 1rem;

    .van-button {
      width: 100%;
    }
  }
}

.coupon-item-style {
  padding: 1rem;
  background: center no-repeat url("@/assets/coupon-bg.png");
  background-size: 100% 100%;
  // justify-content: space-between;
}

::v-deep .van-field__control {
  height: 3rem;
  font-size: large;
}

.paste {
  position: absolute;
  right: 0.5rem;
  top: 1.25rem;

  border-left: 1px solid #232323;
  color: #3d3d3d;
}

.mobile-icon {
  width: 1rem;
  height: 1.5rem;
  position: absolute;
  left: 0.5rem;
  top: 1.25rem;
  z-index: 111;
}

.ipt-style {
  padding: 0;
}

::v-deep .van-field__body {
  border-radius: 0.5rem;
  background: #f7f9fb;
  padding: 0.5rem 5rem 0.5rem 2rem;
}

.bottom-popup {
  padding: 1rem;

  &-title {
    margin-top: 0;
    padding-left: 1rem;
    position: relative;

    &::before {
      content: "";
      width: 0.5rem;
      height: inherit;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background: linear-gradient(90deg, #f15f3e 0%, #ea392b 100%);
      border-radius: 0.5rem;
    }
  }
}

.bottom-btn {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem 3.5rem;
  background: #f7f7f7;
}

.commodity-card {
  padding: 0;
  background: transparent;

  .van-card__title {
    font-size: large;
    line-height: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.product-list {
  // padding-top: 3rem;
  list-style: none;
  //overflow-y: scroll;
  //height: 100vh;

  &-item {
    margin: 0.5rem;
    padding: 0.5rem;
    border-radius: 1rem;
    background: #ffffff;
  }
}

.limited_time_discount {
  padding: 0.5rem;
  border-radius: 0 1rem 1rem 0;
  position: fixed;
  top: 10%;
  left: 0;
  z-index: 11;

  .van-button__text {
    display: flex;
    align-items: center;
  }
}
</style>