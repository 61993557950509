<template>
  <div id="app">
    <!--     <keep-alive>
      <router-view v-if=" istoken && $route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view> -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      // istoken: false
    };
  },
  /*   created() {
      if (!localStorage.getItem('token')) {
        this.$router.push('/signIn')
      } else {
        this.istoken = true
      }
    }, */
};
</script>

<style lang="scss">
.tags-left {
  width: fit-content;
  background: #F8F5F1;
  color: #C5A47F;
  margin-right: 0.1rem;
  padding-right: 0.1rem;
  border-radius: 1rem;
  font-size: smaller;
}

.delete-line {
  position: relative;
  font-size: large;
  background-image: linear-gradient(to right, #F15F3E50, #EA392B50);
  -webkit-background-clip: text;
  color: transparent;

  &::after {
    content: '';
    height: 0.1rem;
    background: linear-gradient(180deg, #F15F3E50, #EA392B50);
    position: absolute;
    left: 0;
    right: -15%;
    top: 50%;
  }
}

.text-gradient {
  -webkit-background-clip: text;
  color: transparent;
  font-size: smaller
}

.agrmnt-style {
  justify-content: center;
  margin-bottom: 0.5rem;
  font-size: small;

  .dot {
    padding: 0.5rem;
    border-radius: 50%;
    margin-right: 0.5rem;
  }

  .default-dot {
    border: 0.1rem solid #D0D0D0;
  }

  .check-dot {
    border: 0.1rem solid #EA3347;
    position: relative;

    &::before {
      content: '';
      width: 0.5rem;
      height: 0.5rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #EA3347;
      border-radius: 50%;
    }
  }
}

/* 隐藏 Chrome、Safari 和 Opera 的滚动条 */
::-webkit-scrollbar {
  display: none;
}

.align-items-center {
  display: flex;
  align-items: center;
}

body {
  margin: 0;
  min-height: 100vh;
  background: #F6F7FB;
}

.outer-frame {
  padding: 1rem;
  background-color: #fff;
  border-radius: 1rem 1rem 0 0;
  min-height: calc(100vh - 2.5rem);
}

#app {
  max-width: 100vw;
}
</style>
