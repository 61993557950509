import signIn from "@/pages/SignIn/signIn.vue";
import homePage from "@/pages/Home/homePage.vue";
import rechargeRecord from "@/pages/RechargeRecord/rechargeRecord.vue"
import rechargeDetails from "@/pages/RechargeRecord/rechargeDetails.vue";
import rechargeInfo from "@/pages/RechargeRecord/rechargeInfo.vue";
import detailsPage from "@/pages/Home/detailsPage.vue";
// import couponList from "@/pages/Home/couponList.vue";
import mine from "@/pages/mine/index.vue";
import revisePhone from "@/pages/mine/subPage/revisePhone.vue";
import coupon from "@/pages/mine/subPage/coupon.vue";
import aboutUs from "@/pages/mine/subPage/aboutUs.vue";

export default [{
    path: '/signIn',
    component: signIn,
    name: 'signIn',
    meta: {
        title: '盲盒折扣券',
        requiresAuth: false,
    }
}, {
    path: '/',
    component: homePage,
    name: 'homePage',
    meta: {
        title: '尊享折扣'
    }
}, 
/* {
    path: '/couponList',
    component: couponList,
    name: 'couponList',
    meta: {
        title: '权益年卡 6折购买',
        requiresAuth: true,
        keepAlive: true,
    }
},  */
{
    path: '/rechargeRecord',
    component: rechargeRecord,
    name: 'rechargeRecord',
    meta: {
        title: '我的订单',
    }
}, {
    path: '/rechargeDetails',
    component: rechargeDetails,
    name: 'rechargeDetails',
    meta: {
        title: '订单详情'
    }
}, {
    path: '/rechargeInfo',
    component: rechargeInfo,
    name: 'rechargeInfo',
    meta: {
        title: '充值信息'
    }
}, {
    path: '/detailsPage',
    component: detailsPage,
    name: 'detailsPage',
    meta: {
        title: '权益详情'
    }
}, {
    path: '/mine',
    component: mine,
    name: 'mine',
    meta: {
        title: '个人中心'
    }
}, {
    path: '/revisePhone',
    component: revisePhone,
    name: 'revisePhone',
    meta: {
        title: '修改手机号'
    }
}, {
    path: '/coupon',
    component: coupon,
    name: 'coupon',
    meta: {
        title: '优惠券'
    }
}, {
    path: '/aboutUs',
    component: aboutUs,
    name: 'aboutUs',
    meta: {
        title: '关于我们'
    }
}
]