<template>
  <div style="padding: 0.5rem 0.5rem 0 0.5rem">
    <div class="outer-frame">
      <div style="display: flex">
        <div class="coupon-activity">COUPON ACTIVITY</div>
        <div class="right-icon">
          <img alt="" src="@/assets/discount-coupons.png" />
        </div>
      </div>
      <h2 style="letter-spacing: 1rem">折扣券活动</h2>
      <p>登录可领取福利哦~</p>
      <div style="text-align: center">
        <img alt="" class="login-page-image" src="@/assets/loginPageImage.png" />
      </div>
      <van-field v-model="signInfo.phone" :error="error.bool" :error-message="error.msg" :formatter="formatter"
        class="ipt-style" clearable format-trigger="onBlur" maxlength="11" placeholder="请输入手机号" type="number" />
      <van-field v-model="signInfo.verifyCode" class="ipt-style" clearable maxlength="6" placeholder="请输入验证码"
        type="number">
        <template #button>
          <van-button :disabled="disabled" color="linear-gradient(to right, #F15F3E, #EA392B)" size="mini"
            type="primary" @click="GetCode">
            {{ verifyCodeText }}
          </van-button>
        </template>
      </van-field>
      <div class="submit-style">
        <van-button color="#FAE562" round size="large" type="default" @click="LogInNow">
          <span style="color: initial">立即登录</span>
        </van-button>
      </div>
      <div class="align-items-center agrmnt-style">
        <div class="align-items-center" @click="checked = !checked">
          <div :class="checked ? 'check-dot' : 'default-dot'" class="dot"></div>
          我已阅读并同意
        </div>
        <span style="color: #ea334790" @click="popupShow = true">《服务条款》和《隐私政策》</span>
      </div>
    </div>
    <van-popup v-model="popupShow" closeable round style="width: 95%">
      <agreementVue />
      <div style="margin: 0 1rem 1rem 1rem">
        <van-button color="linear-gradient(to right, #F15F3E, #EA392B)" round size="large" type="default"
          @click="(checked = true), (popupShow = false)">
          我已阅读并同意此条款政策
        </van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getcode, verify } from "@/utils/api";
import agreementVue from "@/pages/SignIn/agreement.vue";

export default {
  name: "signInIdx",
  components: {
    agreementVue,
  },
  data() {
    return {
      popupShow: false,
      checked: true,
      signInfo: {
        phone: "",
        verifyCode: "",
      },
      error: {
        bool: false,
        msg: "",
      },
      verifyCodeText: "获取验证码",
      disabled: false,
    };
  },
  beforeRouteEnter(to, form, next) {
    localStorage.clear();
    next();
  },
  methods: {
    LogInNow() {
      
      if (!this.checked) {
        this.$toast("请勾选同意隐私协议");
      } else if (!this.signInfo.phone && !this.signInfo.verifyCode) {
        this.$toast("请输入手机号和验证码");
      } else if (!this.signInfo.phone) {
        this.$toast("请输入手机号");
      } else if (!this.signInfo.verifyCode) {
        this.$toast("请输入验证码");
      } else if (this.signInfo.phone && this.signInfo.verifyCode) {
        verify(this.signInfo).then((res) => {
          if (res.token) {
            this.$GlobalEvent.setStorage("phone", this.signInfo.phone);
            this.$GlobalEvent.setStorage("token", res.token);
            this.$router.replace("/");
          }
        });
      }
    },
    errorEvent(msg) {
      this.error = {
        bool: true,
        msg: msg,
      };
      return false;
    },
    formatter(value) {
      var myreg = /^1[3-9]\d{9}$/;
      if (!this.signInfo.phone) {
        return null;
      }
      if (!myreg.test(this.signInfo.phone)) {
        this.errorEvent("手机号码格式错误");
        return value;
      } else {
        this.error = this.$options.data.call(this).error;
        return value;
      }
    },
    GetCode() {
      
      let self = this;
      var time = 120;

      
      if (!this.signInfo.phone) {
        self.disabled = this.errorEvent("手机号码不能为空");
        return;
      } else if (this.signInfo.phone.length < 11) {
        this.errorEvent("手机号码格式错误");
      } else {
        self.disabled = true;
        getcode({
          phone: this.signInfo.phone,
        });
      }

      var timer = setInterval(fun, 1000);

      function fun() {
        time--;
        if (time >= 0) {
          self.verifyCodeText = time + "s后刷新";
        } else if (time < 0) {
          self.verifyCodeText = "获取验证码";
          self.disabled = false;
          clearInterval(timer);
          time = 120;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.van-checkbox {
  justify-content: center;
}

.submit-style {
  text-align: center;
  padding: 1rem 2rem;
}

.ipt-style {
  padding: 0;
}

::v-deep .van-field__value {
  margin-bottom: 1rem;

  .van-field__body {
    height: 2rem;
  }
}

::v-deep .van-field__body {
  border: 1px solid #d6d6d6;
  border-radius: 0.5rem;
  background: #f7f9fb;
  padding: 0.5rem;
}

.login-page-image {
  width: 15.625rem;
  height: 15.625rem;
}

.right-icon {
  background: #ea3347;
  margin-left: 0.5rem;
  padding: 0 1rem 0 0.5rem;
  border-radius: 0 5rem 5rem 0;
  display: flex;
  align-items: center;

  img {
    width: 2.469rem;
    height: 2rem;
  }
}

.coupon-activity {
  padding: 0.5rem;
  background: #ea3347;
  font-size: 1.5rem;
  font-weight: 900;
  color: #ffffff;
}
</style>
