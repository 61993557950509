<template>
  <div style="padding: 0.5rem 0.5rem 0 0.5rem;">
    <div class="outer-frame">
      <van-field v-model="signInfo.phone" :error="error.bool" :error-message="error.msg" :formatter="formatter"
                 class="ipt-style"
                 clearable format-trigger="onBlur"
                 label="手机号" maxlength="11"
                 placeholder="请输入手机号"
                 type="number"/>
      <van-field v-model="signInfo.verifyCode" class="ipt-style" label="验证码" clearable maxlength="6"
                 placeholder="请输入验证码"
                 type="number">
        <template #button>
          <van-button :disabled="disabled" color="linear-gradient(to right, #F15F3E, #EA392B)" size="mini"
                      type="primary" @click="GetCode">
            {{ verifyCodeText }}
          </van-button>
        </template>
      </van-field>
      <div class="submit-style">
        <van-button color="linear-gradient(to right, #F15F3E, #EA392B)" round size="large">
          <span style="color: #fff">提交</span>
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import {getcode, verify} from '@/utils/api'

export default {
  name: "signInIdx",
  components: {},
  data() {
    return {
      popupShow: false,
      checked: true,
      signInfo: {
        phone: '',
        verifyCode: ''
      },
      error: {
        bool: false,
        msg: ''
      },
      verifyCodeText: '获取验证码',
      disabled: false
    }
  },
  methods: {
    LogInNow() {
      if (!this.checked) {
        this.$toast('请勾选同意隐私协议')
      } else if (!this.signInfo.phone && !this.signInfo.verifyCode) {
        this.$toast('请输入手机号和验证码');
      } else if (!this.signInfo.phone) {
        this.$toast('请输入手机号')
      } else if (!this.signInfo.verifyCode) {
        this.$toast('请输入验证码')
      } else if (this.signInfo.phone && this.signInfo.verifyCode) {
        verify(this.signInfo).then((res) => {
          if (res.token) {
            this.$GlobalEvent.setStorage('phone', this.signInfo.phone)
            this.$GlobalEvent.setStorage('token', res.token)
            this.$router.replace('/')
          }
        })
      }
    },
    errorEvent(msg) {
      this.error = {
        bool: true,
        msg: msg
      }
      return false
    },
    formatter(value) {
      var myreg = /^1[3-9]\d{9}$/;
      if (!this.signInfo.phone) {
        return null
      }
      if (!myreg.test(this.signInfo.phone)) {
        this.errorEvent('手机号码格式错误')
        return value
      } else {
        this.error = this.$options.data.call(this).error
        return value
      }
    },
    GetCode() {
      let self = this
      var time = 120;

      if (!this.signInfo.phone) {
        self.disabled = this.errorEvent('手机号码不能为空')
        return
      } else if (this.signInfo.phone.length < 11) {
        this.errorEvent('手机号码格式错误')
      } else {
        self.disabled = true;
        getcode({
          phone: this.signInfo.phone,
        })
      }

      var timer = setInterval(fun, 1000);

      function fun() {
        time--;
        if (time >= 0) {
          self.verifyCodeText = time + "s后刷新";
        } else if (time < 0) {
          self.verifyCodeText = "获取验证码";
          self.disabled = false;
          clearInterval(timer);
          time = 120;
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .van-field__label {
  width: fit-content;
}


.submit-style {
  text-align: center;
  padding: 1rem 2rem;
}

.ipt-style {
  padding: 0;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

::v-deep .van-field__value {

  .van-field__body {
    height: 2rem;
  }
}

::v-deep .van-field__body {
  border: 1px solid #D6D6D6;
  border-radius: 0.5rem;
  background: #F7F9FB;
  padding: 0.5rem;
}
</style>