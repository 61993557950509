<template>
  <div>
    <navigation-bar/>
    <div style="height: 3rem"></div>
    <div class="product-item">
      <van-row class="align-items-center" gutter="20">
        <van-col span="6">
          <div style="position: relative">
            <van-image
                :src="detailinfo.merchandiseLogoUrl"
                height="70"
                width="70"
                @click="PicturePreview"
            />
            <div class="order-status">
              <van-icon v-if="detailinfo.state == 2" color="#00D257" name="checked"/>
              <van-icon v-else-if="detailinfo.state ==3" color="#4C4C4C" name="clear"/>
              <van-icon v-else-if="detailinfo.state== 1" color="#EA3347" name="clock"/>
            </div>
          </div>
        </van-col>
        <van-col span="12">
          <div>
            <h3 class="van-ellipsis" style="margin: 0;">{{ detailinfo.rechargeName }} </h3>
            <div class="order-time">{{ detailinfo.rechargeTime }}</div>
            <van-tag v-if="detailinfo.state == 2" color="#00D257">充值成功</van-tag>
            <van-tag v-else-if="detailinfo.state ==3" color="#4C4C4C">已退款</van-tag>
            <van-tag v-else-if="detailinfo.state== 1" color="#EA3347">充值中</van-tag>
          </div>
        </van-col>
        <van-col span="6">
          <div class="amount van-ellipsis">
            ¥<span style="font-size: x-large">{{ $GlobalEvent.towNumber(detailinfo.merchandisePaymentPrice) }}</span>
          </div>
        </van-col>
      </van-row>
    </div>
    <div class="product-item">
      <p class="align-items-center">
        <van-icon name="description-o" size="25"/>
        <strong>订单详情</strong>
      </p>
      <div class="product-item-content">
        <p>充值账号：{{ $GlobalEvent.Hyposensitization(detailinfo.rechargeAccount) }}</p>
        <p>充值产品：<span style="color:#999999 ">{{ detailinfo.rechargeName }} </span></p>
        <p>订单号码：<span style="color:#999999 ">{{ detailinfo.orderNo }}</span></p>
        <p>订单状态：
          <van-tag v-if="detailinfo.state == 2" color="#00D257">已完成</van-tag>
          <van-tag v-else-if="detailinfo.state ==3" color="#4C4C4C">已退款</van-tag>
          <van-tag v-else-if="detailinfo.state== 1" color="#EA3347">充值中</van-tag>
        </p>
        <p>购买手机号：<span style="color:#999999 ">{{
            $GlobalEvent.Hyposensitization(detailinfo.rechargeAccount)
          }}</span>
        </p>
        <p>下单时间：<span style="color:#999999 ">{{ detailinfo.rechargeTime }}</span></p>
        <p>支付方式：<span style="color:#999999">微信支付</span></p>
        <p>支付时间：<span style="color:#999999 ">{{ detailinfo.createTime }}</span></p>
        <p>数量：<span style="color:#999999 ">1</span></p>
        <p>商品总价：<span style="color:#999999 ">{{ detailinfo.merchandiseFaceValue }}元</span></p>
        <p>折后立减：
          <span v-if="detailinfo.actualPaymentPrice < detailinfo.merchandiseFaceValue"
                style="color:#999999 ">{{
              $GlobalEvent.towNumber(detailinfo.merchandiseFaceValue * 0.6)
            }}元</span>
          <span style="color:#999999 ">未使用折扣券</span>
        </p>
        <p>实际支付：{{ detailinfo.actualPaymentPrice }}元</p>
      </div>
    </div>
  </div>
</template>
<script>
import {ImagePreview} from "vant";
import {rechargeOrderDetail} from "@/utils/api"

export default {
  name: "rechargeDetails",
  data() {
    return {
      detailinfo: {}
    }
  },
  mounted() {
    this.GetDetailInfo(this.$route.query.id)
  },
  methods: {
    GetDetailInfo(Id) {
      rechargeOrderDetail({
        id: Id
      }).then(res => {
        this.detailinfo = res
        console.log(res, "<<<<<<<<<<<<<<<<<<<<<<<")
      })
    },
    PicturePreview() {
      ImagePreview(['https://img01.yzcdn.cn/vant/cat.jpeg']);
    }
  }
}
</script>

<style lang="scss" scoped>
.order-status {
  position: absolute;
  right: 0;
  bottom: 0;
  background: #ffffff;
  padding: 0.15rem;
  border-radius: 50% 0 0 0;
}

.order-time {
  color: #999999;
  margin: 0.5rem 0;
}

.amount {
  -webkit-background-clip: text;
  color: transparent;
  font-size: medium;
  background-image: linear-gradient(to right, #F15F3E, #EA392B);
}

.product-item {

  &-content {
    padding: 0.5rem 1rem;
    margin: 0 1rem 1rem 1rem;
    background: #F7F9FB;
    border-radius: 1rem;
  }

  margin: 1rem;
  padding: 0.5rem;
  border-radius: 1rem;
  background: #ffffff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
}
</style>