<template>
  <div class="Position">
    <div class="forTheRecord">京ICP备2020036467号-2</div>
    <van-tabbar v-model="currentActive" @change="toView" :fixed="false">
      <van-tabbar-item v-for="(i, idx) in tabbar" :key="idx">
        <span>{{ i.name }}</span>
        <template #icon="props">
          <img :src="props.active ? i.active : i.inactive" height="20" width="20" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: "tabbarNavigation",
  props: {
    active: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      currentActive: 0,
      tabbar: [
        {
          name: '首页',
          url: '/',
          active: require('@/assets/shopping-cart-checked.png'),
          inactive: require('@/assets/shopping-cart.png'),
        },
        {
          name: '我的',
          url: '/mine',
          active: require('@/assets/myself-checked.png'),
          inactive: require('@/assets/myself.png'),
        }
      ]
    }
  },
  watch: {
    active: {
      immediate: true,
      handler(val) {
        this.currentActive = val
      }
    }
  },
  methods: {
    toView(e) {
      if (e == 1 && !this.$GlobalEvent.getStorage('token')) {
        this.$toast('请先登录')
        this.$router.push('/signIn')
        return
      }
      this.$router.push(this.tabbar[e].url)
    }
  }
}
</script>

<style scoped lang="scss">
.Position {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.forTheRecord {
  text-align: center;
  color: #9EA0A5;
  font-size: small;
  padding: 0.5rem 0;
  background-color: #fff
}
</style>