import {Toast} from "vant";

export function showLoading(bool) {
    if (bool) {
        return Toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true, loadingType: 'spinner', message: "加载中..."
        });
    }
    if (!bool) {
        return Toast.clear();
    }
}

export function toastFail() {
    return Toast.fail('抱歉，系统开小差了,请稍后再试');
}

export function toastClear() {
    return Toast.clear();
}

export function toast(msg) {
    return Toast(msg);
}