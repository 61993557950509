import Vue from 'vue'
import App from './App.vue'
import router from '@/router/index.js'
import Vant from 'vant';
import 'vant/lib/index.css';
import VueClipboard from 'vue-clipboard2'
import navigationBar from "@/components/NavigationBar.vue";
import tabbarNavigation from "@/components/tabbarNavigation.vue";

// 引入mescroll的vue组件
import MescrollVue from 'mescroll.js/mescroll.vue'

Vue.config.silent = true
Vue.config.productionTip = false


Vue.prototype.$GlobalEvent = {
    towNumber(val) {
        return Number(val).toFixed(2)
    },
    endOfYear() {
        const now = new Date();
        const year = now.getFullYear();
        // 由于JavaScript中的月份是从0开始的，所以11代表12月
        const lastDayOfMonth = new Date(year, 11, 31);
        return lastDayOfMonth.toLocaleDateString();
    },
    Hyposensitization(e) {
        var pat = /(\d{3})\d*(\d{4})/
        if (e) {
            return e.replace(pat, '$1****$2');
        } else {
            return null
        }
    },
    setStorage(key, val) {
        const type = typeof val
        if (type == "object") {
            localStorage.setItem(key, JSON.stringify(val))
        } else {
            localStorage.setItem(key, val)
        }
    },
    getStorage(key) {
        return localStorage.getItem(key)
    },
}
Vue.use(Vant)
Vue.use(VueClipboard)
Vue.component('navigationBar', navigationBar)
Vue.component('MescrollVue', MescrollVue)
Vue.component('tabbarNavigation', tabbarNavigation)
new Vue({
    router, render: h => h(App),
}).$mount('#app')
