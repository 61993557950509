<template>
  <div style="overflow: hidden">
    <van-cell-group>
      <van-cell :value="$GlobalEvent.getStorage('phone')" is-link title="手机" @click="dialogShow = true" />
      <van-cell is-link title="我的订单" to="rechargeRecord" />
      <van-cell is-link title="优惠券" to="coupon" />
    </van-cell-group>

    <van-cell-group>
      <van-cell is-link title="关于我们" to="aboutUs" />
      <van-cell is-link title="注销账号" @click="cancelAccount" />
    </van-cell-group>

    <div class="submit-style">
      <router-link to="/signIn">
        <van-button color="#FAE562" round size="large" type="default">
          <span style="color: initial">退出登录</span>
        </van-button>
      </router-link>
    </div>

    <van-dialog v-model="dialogShow" show-cancel-button title="请输入验证码验证手机号" @confirm="dialogConfirm">
      <van-field v-model="verifyCode" class="ipt-style" clearable maxlength="6" placeholder="请输入验证码" type="number">
        <template #button>
          <van-button color="linear-gradient(to right, #F15F3E, #EA392B)" size="mini" type="primary" @click="GetCode">
            {{ verifyCodeText }}
          </van-button>
        </template>
      </van-field>
    </van-dialog>

    <tabbarNavigation :active="1" />
  </div>
</template>

<script>
import { getcode } from "@/utils/api";
import { Dialog } from 'vant';

export default {
  name: "minePage",
  data() {
    return {
      dialogShow: false,
      verifyCode: '',
      verifyCodeText: '获取验证码',
    }
  },
  methods: {
    cancelAccount() {
      Dialog.confirm({
        title: '提示',
        confirmButtonText: "我再想想",
        cancelButtonText: "继续",
        message: '您即将注销您的账户，\n' +
          '请确认是否继续？',
      }).catch(() => {
        Dialog.confirm({
          title: '提示',
          confirmButtonText: "我再想想",
          cancelButtonText: "确认注销",
          message: '注销账户后您将失去所有账户数据\n' +
            '和优惠券，且无法恢复。\n' +
            '您确定要注销吗？',
        }).catch(() => {
          this.$toast('注销成功')
          this.$router.push('/signIn')
        });
      });
    },
    dialogConfirm() {
      this.$router.push('/revisePhone')
    },
    GetCode() {
      let self = this
      var time = 120;
      var timer = setInterval(fun, 1000);

      getcode({
        phone: this.$GlobalEvent.getStorage('phone'),
      })

      function fun() {
        time--;
        if (time >= 0) {
          self.verifyCodeText = time + "s后刷新";
        } else if (time < 0) {
          self.verifyCodeText = "获取验证码";
          self.disabled = false;
          clearInterval(timer);
          time = 120;
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .van-toast {
  background-color: #fff !important;
}

::v-deep .van-dialog__content {
  padding: 1.5rem;
}

.ipt-style {
  padding: 0;
  line-height: 0;
}

::v-deep .van-field__body {
  border: 1px solid #D6D6D6;
  border-radius: 0.5rem;
  background: #F7F9FB;
  padding: 0.5rem;
}

.submit-style {
  text-align: center;
  padding: 2rem;
}

.van-cell-group {
  margin-top: 0.5rem;
  display: block;
}
</style>