<template>
  <div>
    <div style="text-align: center;padding-top: 10rem">
      <img alt="" height="76" src="@/assets/xgy-logo.png" width="193">
    </div>
    <p>当前版本：1.0.0</p>
    <p style="color: #EA392B" @click="popupShow = true">《服务条款及隐私政策》</p>

    <div class="forTheRecord">
      <div class="align-items-center" style="justify-content: center">
        <img alt="" height="14.5" src="@/assets/customerService-icon.png" width="14">
        <span style="padding: 0 1rem;border-right: 1px solid #E7E9EA;">客服电话：{{ phone }}</span>
        <small>
          <div class="align-items-center" style="padding-left: 0.5rem" @click="copy">
            <img alt="" src="@/assets/copy-icon.png" style="width: 14px;height: 15px;margin: 0 0.5rem"/>复制
          </div>
        </small>
      </div>
      <small style="color: #9EA0A5;">京ICP备2020036467号-2</small>
    </div>
    <van-popup v-model="popupShow" closeable round style="width: 95%">
      <agreementVue/>
    </van-popup>
  </div>
</template>

<script>
import agreementVue from "@/pages/SignIn/agreement.vue";

export default {
  name: "aboutUs",
  components: {agreementVue},
  data() {
    return {
      popupShow: false,
      phone: '19198096073'
    }
  },
  methods: {
    copy() {
      this.$copyText(this.phone).then(
          () => {
            this.$toast('复制成功');
          },
          () => {
            this.$toast('复制失败');
          }
      );
    }
  }
}
</script>

<style lang="scss" scoped>

.forTheRecord {
  position: fixed;
  bottom: 1rem;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0.5rem 0;
}

p {
  text-align: center;
}
</style>