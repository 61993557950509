import axios from "axios";
import jsrsasign from 'jsrsasign'
import router from "@/router";
import { showLoading, toast, toastClear, toastFail } from "@/utils/ToastEvent";

let privatekey = "-----BEGIN PRIVATE KEY-----\n" + "MIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQDA2C6px31PGCvr\n" + "xXQ3jvbYyjObOMJ7oGyyH94TJ9lTHGgofweKPffNtaFVYdI5t3ZU8G9g3u2enCes\n" + "qIumgommw7R35iMYd4iF/PLeJ4FE+ZRJdKqy443lTniDWS7Zt6jYb4opypIyKdNa\n" + "Wi/rcnuFiEXEdmTjPgotNXhsFkcaswV7P5VeTkQEh1TuIznevh0n/GIJstD6xWnP\n" + "f/vFB67UZoMIM87mmppx69DCOaQAZJU7QQh6ZZElVqCqXeZ+wQLb3G6rHj5U3I71\n" + "bLjwEg2Htri88XmbIDKGrtXSOC6IGymXVvz6cf5p18ujr8xkHTnsCvCvDZKeg4c3\n" + "AGnOJPF1AgMBAAECggEAC+HGDkPyXps4qZBC9uFiKD1dLKU0pnVd/SOlhTyhUVOg\n" + "v04EYuoqiIw2YpJiZ5f8PIOL+UYDEhDBCQxhfmLwpEvOpMyTQQCscsUBGdsJRfyy\n" + "vEvp8JfAuG47Uqx+DIAacSt+MdD/bQPSt/Byn2kwXyvkUJSS5sV9zwdKYi6FNwnR\n" + "JVZzm1exENXUlCIku7EJEfFjhPNaD0XUH1L3JVUcN8i/W7p7ydeEcrr7eCM+fA9m\n" + "41q7EK2N7bCgjBIwC6xaRIi4lHDFjvjnou6Y7agB5koy5A28QxkqH95zoZv6Ktth\n" + "xHU4vt9WnFaQ3f0Yb2P11TuxbH9eizPiyx3x2KPCAQKBgQD8FaZQFSVnK2NfE/IU\n" + "lZu98z8shEp72Qt+aWSLKEdagBDfNENXUKv3RnF4/saFvKc0soW7V/SU4dbxtrYH\n" + "Kb29N6TEf7IOUmwElM7er8X7FNq335TvyX69iyXzkOIoarh4jMp7bjc21MDpCQwB\n" + "KDD4rVBTIdR7kXePn48WFZhdwQKBgQDD1vq3aivAywJlj7ee5JmjXCS1TzXuQtOy\n" + "hXvGTqEKGO7c+4JwdulLKHiOFnSZrkFPn1NNbPUx0bpf6fkDmrmbjXVX7rqMFEnU\n" + "TzsWdoGbn1u9A1xDxmHa3E52eah0gDAPg5NnS48BKwOJLZkdv0IEj6eN8Zg/Ve1I\n" + "bGmIDOSotQKBgD5GIuXZs3m/MDdGuCD5rJXKR6r5q2TZA4vhAWOXOblTtddOE+y4\n" + "EyNtFY8scWKMbkoIaB0V52f4Op9P4bv3RdY+rseUPCppliGzmT9N8PzL9BREeYib\n" + "IGI2w9Nd0WDmrFergEHpwl4bROTMxVhr0107g4Jl8xXro7upUTHFd/SBAoGAZiQI\n" + "zYKlYT9Ad8H2TfwCma4kQyAWC7d0/EYcYTh/S2nnov/Wb1vFn3pzLgBOPh0hWSrK\n" + "4rXySFKPdxjr1FJtfdDQo9USHJ80RnQwaC3UuVrrGPDYvUyJmwGONsKK9oApEN5a\n" + "EeTZqA1MIc7Tbi1jfEeEZ0bbViU1xbEtdVTsKYUCgYAL05LEpUIwKvkBWW6te9NQ\n" + "uUo5XSyexRigHXAu/qBHhj5o+Z9YjD/ocqriCCGS/v0BP3UTUegSw+nHeT8gEqAx\n" + "rhZFXP7H2X+K6VckNDQb0RKCp+wmdBUuJKZbpH2ppyHcVtkTtiaCkkb3gUY/Osa/\n" + "dKn2bnOKyLZ96jxewuz+bQ==\n" + "-----END PRIVATE KEY-----";

function sortASCII(header, paramInfo) {
    let obj;
    if (paramInfo instanceof Object) {
        obj = Object.assign({}, header, paramInfo);
    } else {
        obj = Object.assign({}, header, {});
    }
    let keys = Object.keys(obj)
    keys = keys.sort() //参数名ASCII码从小到大排序（字典序）；
    let newArgs = {}
    keys.forEach(function (key) {
        //如果参数的值为空不参与签名；
        if (!(obj[key] === null || obj[key] === undefined || obj[key] === 'undefined' || obj[key] === 'null' || obj[key] === '' || key === 'sign' || obj[key] instanceof Array)) {
            //参数名区分大小写；
            if (obj[key] instanceof Object) {
                let objKeys = Object.keys(obj[key])
                objKeys = objKeys.sort()
                let newObjArgs = {}
                objKeys.forEach(function (item) {
                    newObjArgs[item] = obj[key][item]
                })
                newArgs[key] = JSON.stringify(newObjArgs)
            } else {
                newArgs[key] = obj[key]
            }
        }
    })
    let string = ''
    for (let k in newArgs) {
        string += '&' + k + '=' + newArgs[k]
    }
    string = string.substr(1)
    return string
}


function handleRequestData(obj) {
    let requestObj = {
        "thirdproviderId": "xgy-discount",
        "thirdproviderName": "折扣券",
        "inputCharset": "utf-8",
        "requestTime": new Date().getTime(),
        "version": "1.0.0",
        "system": "H5",
        "signType": "RSA"
    }
    let signStr = sortASCII(requestObj, obj);
    let rsa = jsrsasign.KEYUTIL.getKey(privatekey)
    // 采用SHA256withRSA进行加密
    const sig = new jsrsasign.KJUR.crypto.Signature({
        alg: 'SHA256withRSA'
    })
    // 算法初始化
    sig.init(rsa)
    // 对123456进行加密
    sig.updateString(signStr)
    // 加密后的16进制转成base64，这就是签名了
    requestObj.sign = jsrsasign.hextob64(sig.sign())
    let reqObj = {
        "Request": {
            "MessageHeader": requestObj, "RequestParamInfo": obj
        }
    }
    // console.log(reqObj)
    return reqObj
}

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

const $env = process.env.NODE_ENV;
const $baseUrl = {
    production: '/activity/discount/api',
    development: '/api',
}

// 创建 axios 请求实例
const serviceAxios = axios.create({
    baseURL: $baseUrl[$env],
    // baseURL: process.env["VUE_APP_BASE_URL_PREFIX"] + '/api',
    timeout: 20000, // 请求超时设置
    showLoading: true
});


// 创建请求拦截
serviceAxios.interceptors.request.use((config) => {
    if (config.headers.auth) { // 判断请求是否需要认证
        const token = localStorage.getItem('token')
        config.headers['Authorization'] = 'Bearer ' + token;
    }
    if (config.method === 'post') {
        config.data = handleRequestData(config.data)
    }
    showLoading(config.showLoading)
    // console.log("请求配置", config);
    return config;
}, (error) => {
    showLoading(false)
    toastFail();
    return Promise.reject(error);
});


// 创建响应拦截
serviceAxios.interceptors.response.use((res) => {
    let data = res.data;
    //格式是否正确
    toastClear();
    if (data.Response) {
        if (data.Response.code == 401) {
            router.push('/signin')
            return false
        }
        if (data.Response.code == 500) {
            toast(data.Response.msg)
            return false;
        }
        //是正常返回数据
        if (data.Response.code == 200) {
            if (data.Response?.data) {
                return data.Response.data
            } else {
                return data.Response
            }
        } else {
            //格式错误
            toast(data.Response.msg)
            return Promise.reject('系统报错')
        }
    } else {
        //格式错误
        toast('抱歉，系统开小差了,请稍后再试！');
        return Promise.reject('系统报错')
    }
}, (error) => {
    // toastClear();
    // console.log(error);
    toastFail()
    return Promise.reject(error);
});
export default serviceAxios;
