import Vue from "vue";
import VueRouter from "vue-router";
import pagePath from "@/router/pagePath";


//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

let routes = new Set([...pagePath]); //数组（ [ ] ）内添加其他路由文件
// 创建路由实例，进行路由配置
const router = new VueRouter({
    routes, mode: "history",
    base: '/activity/discount/'
    // base: process.env["VUE_APP_BASE_URL_PREFIX"]
});
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
    /*    if (!localStorage.getItem('token')) {
            next('/signIn')
        } else {
            next()
        }*/
    /*    if (to.matched.some(record => record.meta.requiresAuth)) {
            let token = localStorage.getItem('token')
            if (!token) {
                router.replace('/signIn')
                // next(true)
            } else {
                next()
            }
        } else {
            next()
        }*/
});

// 将路由实例导出
export default router;
