<template>
  <div>
    <navigation-bar />
    <div style="padding: 3.5rem 1rem 7rem 1rem;">
      <van-row class="align-items-center">
        <van-col span="18">
          <h3 style="margin:0">{{ detailedInfo.name }}</h3>
          <P style="margin: 0.5rem 0;color: #999999">普通会员年卡</P>
          <P style="margin: 0.5rem 0;color: #999999">手机、电脑、Pad均可使用</P>
        </van-col>
        <van-col span="6">
          <van-image :src="detailedInfo.logoUrl" height="93" width="93" />
        </van-col>
      </van-row>

      <div v-if="Count > 0 && checked">
        <div class="align-items-center">
          <div class="text-gradient" style="background-image: linear-gradient(to right, #F15F3E, #EA392B);">
            ¥<span style="font-size: x-large">{{ $GlobalEvent.towNumber(detailedInfo.faceValue * 0.6) }}</span>
          </div>
          <div style="color: #D8D8D8;margin: 0 0.5rem">|</div>
          <div class="text-gradient" style="background-image: linear-gradient(to right, #F15F3E50, #EA392B50);">
            原价
            ¥<span class="delete-line">{{ $GlobalEvent.towNumber(detailedInfo.faceValue) }}</span>
          </div>
        </div>
        <div class="tags-left" style="margin: 1rem 0">
          <van-tag color="#EA392B" round size="medium">限时折扣</van-tag>
          有效期 {{ $GlobalEvent.endOfYear() }}
        </div>
      </div>
      <div v-else>
        <div class="text-gradient" style="background-image: linear-gradient(to right, #F15F3E, #EA392B);">
          ¥<span style="font-size: x-large">{{ $GlobalEvent.towNumber(detailedInfo.faceValue) }}</span>
        </div>
      </div>


      <div class="box-style">
        <div class="align-items-center">
          <img alt="" height="20" src="@/assets/label-icon.png" width="20" />
          <h4 class="label-style">奖品类型</h4>
          <span style="margin: 0 0.5rem;color: #D0D0D0">|</span>
          <span style="font-size: small">直充</span>
        </div>
      </div>
      <div class="box-style" style="padding: 0.5rem">
        <img :src="detailedInfo.detailUrl" alt="" width="100%" />
        <div class="bottom-tag">本产品为虚拟物品，一经售出将不支持退换</div>
      </div>
      <div class="bottom-btn">
        <div v-if="Count >= 0" class="align-items-center agrmnt-style">
          <div class="align-items-center" @click="checked = !checked">
            <div :class="checked ? 'check-dot' : 'default-dot'" class="dot"></div>
            <van-tag color="#FFEFD4" round size="large" text-color="#000">
              使用 <span style="color: #F15F3E;margin: 0 0.1rem" @click.stop="couponPop = true">权益年卡6折购买券</span>
              <img alt="" src="@/assets/coupon-icon.png" style="width: 0.875rem;height: 0.75rem">
              <span style="margin: 0 0.5rem">x{{ Count }}</span>
            </van-tag>
          </div>
        </div>
        <van-button block color="linear-gradient(to right, #F15F3E, #EA392B)" round @click="buyNow">
          立即购买
        </van-button>
      </div>
    </div>

    <van-popup v-model="couponPop" :style="{ height: '45%' }" position="bottom " round>
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" class="van-list">
        <van-radio-group v-model="radio">
          <van-row v-for="(el, idx) in listData" :key="idx" gutter="20" class="align-items-center"
            style="margin-bottom: 0.5rem">
            <van-col span="21" class="coupon-item-style align-items-center">
              <div style="color: #EA392B;padding: 0 15% 0 5%">
                <div>
                  <strong>
                    <strong style="font-size: xx-large">{{ el.discount }}</strong>
                    <small>折</small>
                  </strong>
                </div>
                <div><small>{{ el.type }}</small></div>
              </div>
              <div style="font-size: small;color: #FFEFD4">
                <div>
                  <strong>{{ el.title }}</strong>
                </div>
                <div style="margin: 0.1rem 0">券码来源：{{ el.source }}</div>
                <div>{{ el.time }}</div>
              </div>
            </van-col>
            <van-col span="3">
              <van-radio :name="el.id">
                <template #icon="props">
                  <img width="20" height="20"
                    :src="props.checked ? require('@/assets/Selected-icon.png') : require('@/assets/unSelected-icon.png')"
                    alt="">
                </template>
              </van-radio>
            </van-col>
          </van-row>
        </van-radio-group>
      </van-list>
      <van-row class="coupon-btn" gutter="20">
        <van-col span="12">
          <van-button round color="#EA392B" plain @click="couponPop = false">返回</van-button>
        </van-col>
        <van-col span="12">
          <van-button round color="linear-gradient(to right, #F15F3E, #EA392B)" :disabled="!radio"
            @click="couponPop = false">确认</van-button>
        </van-col>
      </van-row>
    </van-popup>

    <van-popup v-model="popupShow" :style="{ height: '45%' }" position="bottom " round>
      <div class="bottom-popup">
        <h3 class="bottom-popup-title">请输入您的充值账号</h3>
        <div style="position:relative;">
          <img alt="" class="mobile-icon" src="@/assets/mobile-icon.png" />
          <van-field v-model="topUpAccount" :error="error.bool" :formatter="formatter" class="ipt-style" clearable
            format-trigger="onBlur" maxlength="11" placeholder="请输入正确的充值账号" type="number" />
          <div class="align-items-center paste" @click="pasteEvent">
            <img alt="" src="@/assets/copy-icon.png" style="width: 14px;height: 15px;margin: 0 0.5rem" />粘贴
          </div>
        </div>
        <div style="padding: 0 2rem">
          <div class="align-items-center agrmnt-style" style="margin: 1.5rem 0">
            <van-tag color="#FFEFD4" round size="large" style="margin: 1rem 0" text-color="#F15F3E">
              <span v-if="topUpAccount">
                正在为{{ $GlobalEvent.Hyposensitization(topUpAccount) }}充值，请核对账号！
              </span>
              <span v-else>本产品为虚拟物品，一经售出将不支持退换</span>
            </van-tag>
          </div>
          <van-button block color="linear-gradient(to right, #F15F3E, #EA392B)" round @click="Payment(detailedInfo)">
            确认充值
          </van-button>
        </div>
      </div>
    </van-popup>

    <!--提示弹窗-->
    <van-popup v-model="error.bool" :overlay="false" round style="width: 55%">
      <div class="align-items-center" style="padding: 1rem;">
        <img alt="" src="@/assets/toast-icon.png" style="width: 1.563rem;height: 1.563rem;margin-right: 0.5rem" />
        <span style="color: #4C4C4C">{{ error.msg }}!</span>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { couponCount, payOrder, productDetails } from "@/utils/api";


export default {
  name: "detailsPage",
  data() {
    return {
      checked: true,
      detailedInfo: {},
      Count: 0,
      topUpAccount: '',
      error: {
        bool: false,
        msg: ''
      },
      couponPop: false,
      popupShow: false,
      loading: false,
      finished: true,
      radio: null,
      listData: [
        {
          id: 1,
          discount: 6,
          type: '无门槛',
          title: '仅限【权益年卡6折购买】',
          source: '红卡盲盒抽奖',
          time: '2024.06.26—2025.03.31'
        }, {
          id: 2,
          discount: 6,
          type: '无门槛',
          title: '仅限【权益年卡6折购买】',
          source: '红卡盲盒抽奖',
          time: '2024.06.26—2025.03.31'
        }, {
          id: 3,
          discount: 6,
          type: '无门槛',
          title: '仅限【权益年卡6折购买】',
          source: '红卡盲盒抽奖',
          time: '2024.06.26—2025.03.31'
        }, {
          id: 4,
          discount: 6,
          type: '无门槛',
          title: '仅限【权益年卡6折购买】',
          source: '红卡盲盒抽奖',
          time: '2024.06.26—2025.03.31'
        }
      ],
    }
  },
  mounted() {
    document.body.style.backgroundColor = "#ffffff";
    this.GetCouponCount()
    this.getDetailInfo()
  },
  methods: {
    errorEvent(msg) {
      this.error = {
        bool: true,
        msg: msg || '请输入您的充值账号'
      }
      setTimeout(() => {
        this.error.bool = false
      }, 1000)
      return false
    },
    pasteEvent() {
      let self = this;
      navigator.clipboard.readText().then(text => {
        self.topUpAccount = text
        self.formatter()
      })
    },
    formatter(value) {
      var myreg = /^1[3-9]\d{9}$/;
      if (value) {
        if (!myreg.test(value) && value.length < 11) {
          this.errorEvent('您的手机号格式错误')
          return value
        }
      } else {
        this.errorEvent()
        return null
      }
      return value
    },
    Payment(item) {
      var myreg = /^1[3-9]\d{9}$/;
      if (this.topUpAccount && myreg.test(this.topUpAccount)) {
        payOrder({
          event: "六折购买", //支付描述
          amount: this.Count > 0 && this.checked ? this.$GlobalEvent.towNumber(item.faceValue * 0.6) : this.$GlobalEvent.towNumber(item.faceValue), //支付金额
          payOrderForm: {
            merchandiseId: item.id, //商品id
            account: this.topUpAccount, //充值账号
          }
        }).then(res => {
          console.log(res)
        })

        /*      this.$router.push({
                path: '/rechargeInfo',
                query: {
                  status: status
                }
              })*/
      } else if (!this.topUpAccount) {
        this.errorEvent()
      } else {
        this.errorEvent('您的手机号格式错误')
      }
    },
    getDetailInfo() {
      productDetails({
        id: this.$route.query.code
      }).then(res => {
        this.detailedInfo = res
      })
    },
    buyNow() {
      if (this.$GlobalEvent.getStorage('token')) {
        this.popupShow = true
      } else {
        this.$router.push({
          path: '/signIn'
        })
      }
    },
    GetCouponCount() {
      if (this.$GlobalEvent.getStorage('token')) {
        couponCount({}).then(res => {
          this.Count = res.count
        })
      }
    }
  },
  beforeDestroy() {
    document.body.style.backgroundColor = "#F7F7F7";
  }
};
</script>

<style lang="scss" scoped>
.coupon-btn {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  padding: 1rem 0;

  .van-col {
    text-align: center;
    padding: 0 1rem;

    .van-button {
      width: 100%;
    }
  }
}

.coupon-item-style {
  padding: 1rem;
  background: center no-repeat url("@/assets/coupon-bg.png");
  background-size: 100% 100%;
  // justify-content: space-between;
}

.van-list {
  padding: 1rem 1rem 4.625rem 1rem;
}

::v-deep .van-field__body {
  border-radius: 0.5rem;
  background: #F7F9FB;
  padding: 0.5rem 5rem 0.5rem 2rem;
}

::v-deep .van-field__control {
  height: 3rem;
  font-size: large;
}

.paste {
  position: absolute;
  right: 0.5rem;
  top: 1.25rem;

  border-left: 1px solid #232323;
  color: #3D3D3D;
}

.mobile-icon {
  width: 1rem;
  height: 1.5rem;
  position: absolute;
  left: 0.5rem;
  top: 1.25rem;
  z-index: 111;
}

.bottom-popup {
  padding: 1rem;

  &-title {
    margin-top: 0;
    padding-left: 1rem;
    position: relative;

    &::before {
      content: '';
      width: 0.5rem;
      height: inherit;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background: linear-gradient(90deg, #F15F3E 0%, #EA392B 100%);
      border-radius: 0.5rem;
    }
  }
}

.ipt-style {
  padding: 0;
}

.bottom-btn {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem 3.5rem;
  background: #ffffff;
}

.bottom-tag {
  text-align: center;
  background: #FDF1F4;
  color: #EA392B;
  margin-top: 0.5rem;
  padding: 1rem;
  border-radius: 0.5rem;
}

.content-style {
  margin: 0.5rem 1.75rem;
  font-size: small;
  color: #17191A;
}

.label-style {
  margin: 0 0 0 0.5rem;
}

.box-style {
  padding: 1rem;
  margin: 0.5rem 0;
  background: #F7F7F7;
  border-radius: 0.5rem;
}
</style>