<template>
  <div>
    <navigation-bar/>
    <div style="padding-top: 3rem"></div>
    <div class="product-item">
      <van-row class="align-items-center" gutter="20">
        <van-col span="6">
          <div>
            <van-image
                height="70"
                src="https://img01.yzcdn.cn/vant/cat.jpeg"
                width="70"
                @click="PicturePreview"
            />
          </div>
        </van-col>
        <van-col span="12">
          <div>
            <h3 class="van-ellipsis" style="margin: 0;">Bilibili大会员（年卡） </h3>
            <div class="order-time">2024-02-03 18:12:58</div>
          </div>
        </van-col>
        <van-col span="6">
          <div class="amount van-ellipsis">
            ¥<span style="font-size: x-large">13.74</span>
          </div>
        </van-col>
      </van-row>
    </div>
    <div class="product-item">
      <div style="text-align: center">
        <van-icon :color="querys.status == 0 ? '#00D257':'#EA392B'"
                  :name="querys.status == 0 ? 'checked':'clear'" size="70"/>
        <div>充值{{ querys.status == 0 ? '成功' : '失败' }}</div>
        <!--        <van-icon v-if="querys.status == 2" color="#EA3347" name="clock" size="100"/>-->
      </div>
      <div class="item-style align-items-center" style="justify-content: space-between">
        <span>发放状态：</span>
        <span style="color:#999999">
          发放{{ querys.status == 0 ? '成功' : '失败' }}
          <van-icon :color="querys.status == 0 ? '#00D257':'#EA392B'"
                    :name="querys.status == 0 ? 'checked':'clear'"/>
        </span>
      </div>
      <div class="item-style align-items-center" style="justify-content: space-between">
        <span>抽奖时间：</span>
        <span style="color:#999999">2024.01.30  23:59:59</span>
      </div>
      <div class="item-style align-items-center" style="justify-content: space-between">
        <span>充值时间：</span>
        <span style="color:#999999">2024.01.30  23:59:59</span>
      </div>
      <div class="item-style align-items-center" style="justify-content: space-between">
        <span>充值账号：</span>
        <span style="color:#999999">{{ $GlobalEvent.Hyposensitization('15116236221') }}</span>
      </div>

      <div class="align-items-center" style="padding: 1.5rem 0">
        <van-icon name="description-o" size="25"/>
        <strong>订单详情</strong>
      </div>
      <div class="item-style" style="font-size: 13px;color: #2C2C2C80;border-radius: 0.5rem">
        1、权益详情：20元代金券 <br>
        2、适用范围：无门槛代金券<br>
        3、使用规则：此权益不兑现不找零，过期作废。
      </div>

    </div>
  </div>
</template>
<script>
import {ImagePreview} from "vant";

export default {
  name: "rechargeDetails",
  data() {
    return {
      querys: {}
    }
  },
  mounted() {
    this.querys = this.$route.query
  },
  methods: {
    PicturePreview() {
      ImagePreview(['https://img01.yzcdn.cn/vant/cat.jpeg']);
    }
  }
}
</script>

<style lang="scss" scoped>
.item-style {
  margin: 1rem;
  padding: 0.5rem;
  border-radius: 5rem;
  background: #F7F9FB;
}

.order-time {
  color: #999999;
  margin: 0.5rem 0;
}

.amount {
  -webkit-background-clip: text;
  color: transparent;
  font-size: medium;
  background-image: linear-gradient(to right, #F15F3E, #EA392B);
}

.product-item {
  margin: 1rem;
  padding: 0.5rem;
  border-radius: 1rem;
  background: #ffffff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
}
</style>