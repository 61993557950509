import serviceAxios from "@/utils/request";

//获取验证码
export const getcode = (data) => {
    return serviceAxios({
        url: "/open/sms/send",
        method: "post",
        data,
    });
};
// 免登录
export const authorization = (data) => {
    return serviceAxios({
        url: "/open/authorization/code",
        method: "post",
        showLoading: false,
        data,
    });
};// 登录
export const verify = (data) => {
    return serviceAxios({
        url: "/open/sms/verify",
        method: "post",
        data,
    });
};
// 商品列表
export const commodityList = (data) => {
    return serviceAxios({
        url: "/merchandise/list",
        method: "post",
        showLoading: false,
        data,
    });
};
// 商品详情
export const productDetails = (data) => {
    return serviceAxios({
        url: "/merchandise/detail",
        method: "post",
        data,
    });
};
//订单列表
export const rechargeOrderList = (data) => {
    return serviceAxios({
        url: "/rechargeOrder/list",
        method: "post",
        showLoading: false,
        headers: {
            auth: true
        },
        data,
    });
};
//订单详情
export const rechargeOrderDetail = (data) => {
    return serviceAxios({
        url: "/rechargeOrder/detail",
        method: "post",
        showLoading: false,
        headers: {
            auth: true
        },
        data,
    });
};
// 权益券数量
export const couponCount = (data) => {
    return serviceAxios({
        url: "/coupon/count",
        method: "post",
        showLoading: false,
        headers: {
            auth: true
        },
        data,
    });
};
// 权益券数量
export const payOrder = (data) => {
    return serviceAxios({
        url: "/pay/order",
        method: "post",
        headers: {
            auth: true
        },
        data,
    });
};