<template>
  <div>
    <div style="padding: 0.5rem">
      <van-swipe :autoplay="3000" class="my-swipe" height="110" indicator-color="white">
        <van-swipe-item @click="toBuy">
          <van-image :src="require('@/assets/banner-img1.png')" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="box-share">
      <!--       <div style="text-align: center;margin-top: 5rem">
        <van-image
            :src="require('@/assets/loginPageImage.png')"
            fit="contain"
            height="10rem"
            width="10rem"
        />
        <p><strong>更多优惠敬请期待！</strong></p>
      </div>
      <van-button color="#EA392B" plain round size="large" type="default" @click="toBuy">参与6折购买</van-button> -->
      <couponList />
    </div>
    <tabbarNavigation :active="0"/>
  </div>
</template>

<script>
import couponList from './couponList.vue'


export default {
  name: 'HomePage',
  components: {
    couponList
  },
  methods: {
    toBuy() {
      // this.$router.push('/couponList')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .van-swipe__indicators {
  bottom: 0.05rem;
}

.box-share {
  padding-bottom: 3rem;
  background-color: #fff;
  border-radius: 1rem 1rem 0 0;
  min-height: calc(100vh - 13.5rem);
  position: relative;
}

::v-deep .van-swipe__indicator {
  width: 1rem;
  border-radius: 50%
}

.my-swipe {
  border-radius: 0.5rem;
}
</style>