<template>
  <div>
    <navigation-bar/>
    <mescroll-vue ref="mescroll" :down="mescrollDown" :up="mescrollUp" @init="mescrollInit">
      <ul v-if="dataList.length>0" class="product-list">
        <li v-for="(item,idx) in dataList" :key="idx" class="product-list-item" @click="ViewDetails(item)">
          <van-row class="align-items-center" gutter="20">
            <van-col span="6">
              <div style="position: relative">
                <van-image
                    :src="item.merchandiseLogoUrl"
                    height="70"
                    width="70"
                    @click="PicturePreview"
                />
                <div class="order-status">
                  <van-icon v-if="item.state == 2" color="#00D257" name="checked"/>
                  <van-icon v-else-if="item.state == 3" color="#4C4C4C" name="clear"/>
                  <van-icon v-else-if="item.state == 1" color="#EA3347" name="clock"/>
                </div>
              </div>
            </van-col>
            <van-col span="12">
              <div>
                <h3 class="van-ellipsis" style="margin: 0;">{{ item.rechargeName }} </h3>
                <div class="order-time">{{ item.rechargeTime }}</div>
                <van-tag v-if="item.state == 2" color="#00D257">充值成功</van-tag>
                <van-tag v-else-if="item.state == 3" color="#4C4C4C">已退款</van-tag>
                <van-tag v-else-if="item.state == 1" color="#EA3347">充值中</van-tag>
              </div>
            </van-col>
            <van-col span="6">
              <div class="amount van-ellipsis">
                ¥<span style="font-size: x-large">{{ $GlobalEvent.towNumber(item.merchandisePaymentPrice) }}</span>
              </div>
            </van-col>
          </van-row>
        </li>
      </ul>
      <van-empty v-else description="暂无充值记录" image-size="300"/>
    </mescroll-vue>
  </div>
</template>
<script>
import {ImagePreview} from "vant"
import {rechargeOrderList} from "@/utils/api";


export default {
  name: "rechargeRecord",
  data() {
    return {
      dataList: [],
      mescroll: null,
      mescrollDown: {},
      mescrollUp: { // 上拉加载的配置.
        callback: this.upCallback, // 上拉回调,此处简写; 相当于 callback: function(page, mescroll) { }
      },
    }
  },
  methods: {
    // mescroll组件初始化的回调,可获取到mescroll对象
    mescrollInit(mescroll) {
      this.mescroll = mescroll
    },
    upCallback(page, mescroll) {
      rechargeOrderList({
        page: page.num,
        size: page.size
      }).then(res => {
        if (page.num === 1) this.dataList = []
        this.dataList = this.dataList.concat(res)
        this.$nextTick(() => {
          mescroll.endSuccess(res.length)
        })
      }).catch(() => {
        // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
        mescroll.endErr()
      })
    },
    ViewDetails(item) {
      this.$router.push({
        path: '/rechargeDetails',
        query: {
          id: item.id
        }
      })
    },
    PicturePreview() {
      ImagePreview(['https://img01.yzcdn.cn/vant/cat.jpeg']);
    }
  }
}
</script>
<style lang="scss" scoped>
.order-status {
  position: absolute;
  right: 0;
  bottom: 0;
  background: #ffffff;
  padding: 0.15rem;
  border-radius: 50% 0 0 0;
}

.order-time {
  color: #999999;
  margin: 0.5rem 0;
}

.amount {
  -webkit-background-clip: text;
  color: transparent;
  font-size: medium;
  background-image: linear-gradient(to right, #F15F3E, #EA392B);
}

.product-list {
  list-style: none;
  padding: 3rem 0 0 0;


  &-item {
    margin: 1rem;
    padding: 0.5rem;
    border-radius: 1rem;
    background: #ffffff;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  }
}
</style>