<template>
  <div style="padding: 0.5rem 0.5rem 0 0.5rem;">
    <div class="outer-frame" style="padding: 0.5rem">
      <ul v-if="listData.length > 0">
        <li v-for="(el,idx) in listData" :key="idx" class="coupon-item-style">
          <van-row class="align-items-center">
            <van-col span="6" style="color: #EA392B;text-align: center">
              <div>
                <strong>
                  <strong style="font-size: xx-large">{{ el.discount }}</strong>
                  <small>折</small>
                </strong>
              </div>
              <div><small>{{ el.type }}</small></div>
            </van-col>
            <van-col span="14">
              <div style="font-size: small;color: #FFEFD4">
                <div>
                  <strong>{{ el.title }}</strong>
                </div>
                <div style="margin: 0.1rem 0">券码来源：{{ el.source }}</div>
                <div>{{ el.time }}</div>
              </div>
            </van-col>
            <van-col span="4">
              <van-button class="immediateUse" size="mini" type="default" to="/">立即使用</van-button>
            </van-col>
          </van-row>
        </li>
      </ul>
      <van-empty v-else description="暂无充值记录" image-size="300"/>

    </div>
  </div>
</template>

<script>
export default {
  name: "couponPage",
  data() {
    return {
      listData: [
        {
          id: 1,
          discount: 6,
          type: '无门槛',
          title: '仅限【权益年卡6折购买】',
          source: '红卡盲盒抽奖',
          time: '2024.06.26—2025.03.31'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.van-col {
  text-align: center;
}

.immediateUse {
  border: none;
  color: #EA392B;
}

.coupon-item-style {
  padding: 0.5rem;
  background: center no-repeat url("@/assets/coupon-bg.png");
  background-size: 100% 100%;
}
</style>