<template>
  <div>
    <van-nav-bar :title="pageTitle" fixed @click-left="onClickLeft">
      <template #left>
        <van-icon color="#3D3D3D" name="arrow-left" size="20" />
      </template>
      <template #right>
        <!--        <van-icon v-if="pageTitle == '充值记录'" color="#000" name="weapp-nav" size="20"/>-->

        <!--        <van-icon color="#3D3D3D" name="underway-o" size="20"/>-->
        <!--        <img width="20" height="20" src="@/assets/recharge-record-icon.png" alt=""
                      v-if="pageTitle == '权益年卡 6折购买'" @click="onClickRight"/>-->
      </template>
    </van-nav-bar>
  </div>
</template>
<script>
export default {
  name: "NavigationBar",
  data() {
    return {
      pageTitle: '',
    }
  },
  mounted() {
    this.pageTitle = document.title
  },
  methods: {
    onClickLeft() {
      /*      if (this.pageTitle == '权益年卡 6折购买') {
              this.$router.push('/signIn')
              return
            }*/
      this.$router.back()
    },
    /*    onClickRight() {
          this.$router.push({
            path: '/rechargeRecord'
          })
        }*/
  }
}
</script>
<style lang="scss" scoped></style>